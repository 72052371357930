import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch((err) => err)
}
VueRouter.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: "/",
    name: "scmpHome",
    component: () => import("@/views/scmpHome/scmpHome.vue"),
    meta: {
      title: "首页",
      isBack: false
    }
  },
  {
    path: "/advanceSale",
    name: "advanceSale",
    component: () => import("@/views/shoppingGuide/advanceSale.vue"),
    meta: {
      title: "试穿",
      isBack: false
    }
  },
  {
    path: "/wxLogin",
    name: "login",
    component: () => import("@/views/wxLogin/wxLogin.vue"),
    meta: {
      title: "登录",
      isBack: false
    }
  },
  {
    path: "/inquireLog",
    name: "inquireLog",
    component: () => import("@/views/inquireLog/inquireLog.vue"),
    meta: {
      title: "物流查询"
    }
  },
  {
    path: "/guide/orderGoods",
    name: "orderGoods",
    component: () => import("@/views/shoppingGuide/orderGoods.vue"),
    meta: {
      title: "订单",
      isBack: false
    }
  },
  {
    path: "/guide/createOrder",
    name: "createOrder",
    component: () => import("@/views/shoppingGuide/createOrder.vue"),
    meta: {
      title: "创建订单",
      keepAlive: true
    }
  },
  {
    path: "/guide/orderDetail",
    name: "orderDetail",
    component: () => import("@/views/shoppingGuide/orderDetail.vue"),
    meta: {
      title: "订单详情"
    }
  },
  {
    path: "/guide/orderSuccess",
    name: "orderSuccess",
    component: () => import("@/views/shoppingGuide/orderSuccess.vue"),
    meta: {
      title: "生成订单"
    }
  },
  {
    path: "/guide/goDelivery",
    name: "goDelivery",
    component: () => import("@/views/shoppingGuide/goDelivery.vue"),
    meta: {
      title: "去发货"
    }
  },
  {
    path: "/guide/goExchange",
    name: "goExchange",
    component: () => import("@/views/shoppingGuide/goExchange.vue"),
    meta: {
      title: "去换货"
    }
  },
  {
    path: "/guide/goRefundReturn",
    name: "goRefundReturn",
    component: () => import("@/views/shoppingGuide/goRefundReturn.vue"),
    meta: {
      title: "去退款"
    }
  },
  {
    path: "/guide/refundReturnDetail",
    name: "refundReturnDetail",
    component: () => import("@/views/shoppingGuide/refundReturnDetail.vue"),
    meta: {
      title: "退货退款详情"
    }
  },
  {
    path: "/guide/goReturnPrice",
    name: "goReturnPrice",
    component: () => import("@/views/shoppingGuide/goReturnPrice.vue"),
    meta: {
      title: "去退差价"
    }
  },
  {
    path: "/guide/goPurchased",
    name: "goPurchased",
    component: () => import("@/views/shoppingGuide/goPurchased.vue"),
    meta: {
      title: "去购买"
    }
  },
  {
    path: "/sales/salesList",
    name: "salesList",
    component: () => import("@/views/shoppingGuide/salesList.vue"),
    meta: {
      title: "转销售",
      isBack: false
    }
  },
  {
    path: "/sales/salesDetail",
    name: "salesDetail",
    component: () => import("@/views/shoppingGuide/salesDetail.vue"),
    meta: {
      title: "查看明细"
    }
  },
  {
    path: "/sales/salesFillAmount",
    name: "salesFillAmount",
    component: () => import("@/views/shoppingGuide/salesFillAmount.vue"),
    meta: {
      title: "转销售"
    }
  },
  {
    path: "/sales/salesPosNumber",
    name: "salesPosNumber",
    component: () => import("@/views/shoppingGuide/salesPosNumber.vue"),
    meta: {
      title: "转销售 "
    }
  },
  {
    path: "/sales/transferredSale",
    name: "transferredSale",
    component: () => import("@/views/shoppingGuide/transferredSale.vue"),
    meta: {
      title: "已转销售"
    }
  },
  {
    path: "/sales/unreportedStep1",
    name: "unreportedStep1",
    component: () => import("@/views/shoppingGuide/unreportedSale1.vue"),
    meta: {
      title: "未转销售"
    }
  },
  {
    path: "/sales/unreportedStep2",
    name: "unreportedStep2",
    component: () => import("@/views/shoppingGuide/unreportedSale2.vue"),
    meta: {
      title: "未转销售"
    }
  },
  {
    path: "/sales/unreportedStep3",
    name: "unreportedStep3",
    component: () => import("@/views/shoppingGuide/unreportedSale3.vue"),
    meta: {
      title: "未转销售"
    }
  },
  {
    path: "/sales/unreportedStep4",
    name: "unreportedStep4",
    component: () => import("@/views/shoppingGuide/unreportedSale4.vue"),
    meta: {
      title: "未转销售"
    }
  },
  {
    path: "/sales/interruptList",
    name: "interruptList",
    component: () => import("@/views/shoppingGuide/interruptList.vue"),
    meta: {
      title: "未转销售"
    }
  },
  {
    path: "/biReports",
    name: "biReports",
    component: () => import("@/views/storeManager/biReports.vue"),
    meta: {
      title: "BI报表",
      keepAlive: true
    }
  },
  {
    path: "/manager/approvalList",
    name: "approvalList",
    component: () => import("@/views/storeManager/approvalList.vue"),
    meta: {
      title: "审批列表"
    }
  },
  {
    path: "/manager/approvalList2",
    name: "approvalList2",
    component: () => import("@/views/storeManager/approvalList2.vue"),
    meta: {
      title: "审批列表"
    }
  },
  {
    path: "/manager/approvalDetail",
    name: "approvalDetail",
    component: () => import("@/views/storeManager/approvalDetail.vue"),
    meta: {
      title: "审批详情"
    }
  },
  {
    path: "/manager/approvalDetail2",
    name: "approvalDetail2",
    component: () => import("@/views/storeManager/approvalDetail2.vue"),
    meta: {
      title: "审批详情"
    }
  },
  {
    path: "/manager/orderList",
    name: "mOrderList",
    component: () => import("@/views/storeManager/orderList.vue"),
    meta: {
      title: "订单列表"
    }
  },
  {
    path: "/manager/orderDetail",
    name: "mOrderDetail",
    component: () => import("@/views/storeManager/orderDetail.vue"),
    meta: {
      title: "订单列表"
    }
  },
  {
    path: "/manager/inheritHome",
    name: "inheritHome",
    component: () => import("@/views/storeManager/inheritHome.vue"),
    meta: {
      title: "继承"
    }
  },
  {
    path: "/manager/orderInherit",
    name: "OrderInherit",
    component: () => import("@/views/storeManager/orderInherit.vue"),
    meta: {
      title: "继承"
    }
  },
  {
    path: "/manager/inheritGuide",
    name: "inheritGuide",
    component: () => import("@/views/storeManager/inheritGuide.vue"),
    meta: {
      title: "继承"
    }
  },
  {
    path: "/noAccess",
    name: "noAccess",
    component: () => import("@/views/noAccess/noAccess.vue"),
    meta: {
      title: "暂无权限"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
